<template>
  <div class="dealDetails">
    <!-- 上面部分 -->
    <div class="detailsTop">
      <div class="goodTop">
        <!-- 左边图片展示 -->
        <div class="detailsleft">
          <img :src="goodList.product_image_url" />
          <a class="AmazonBtn"
             :href="goodList.amazon_url">Open in Amazon</a>
        </div>
        <!-- 右边详情显示区 -->
        <div class="detailsRight">
          <!-- 产品结束时间 -->
          <div class="goods-expires"
               v-if="timeBool">
            <i class="el-icon-alarm-clock"></i>
            Listing expires In
            <span>{{timer}} days</span>
          </div>
          <div class="goods-expires"
               v-if="!timeBool"
               style="color: red">
            <i class="el-icon-alarm-clock"
               style="color: #5b5b5b"></i>
            This product has been pulled off shelves
          </div>
          <!-- 产品标题 -->
          <h1>{{goodList.product_title}} </h1>
          <!-- 产品国家 -->
          <div class="country-text">
            <div class="countryBox">
              <span :class="goodList.site_class"></span>
              <span class="type"
                    style="margin-left: 10px">{{goodList.category}}</span>
            </div>
            <!-- 评论点赞 -->
            <div class="chooesBox">
              <div class="likeBox">
                <li class="comments">0</li>
                <!-- <span class="number">0</span> -->
              </div>
              <div class="likeBox">
                <li :class="likeBool ? 'hollow' : 'solid'"
                    ref="like"
                    @click="likeDeal()">{{likeNum}}</li>
                <!-- <span class="number">0</span> -->
              </div>
              <div class="likeBox">
                <li class="good">0</li>
                <!-- <span class="number">0</span> -->
              </div>
              <div class="likeBox">
                <li class="bad">0</li>
                <!-- <span class="number">0</span> -->
              </div>
            </div>
          </div>
          <div class="goodBox">
            <div class="goodLeft">
              <!-- 产品价格与折扣 -->
              <div class="goods-price">
                <!-- ${{goodList.deal_price}} -->
                <b>
                  <i v-if="goodList.site === 'US'">$</i>
                  <i v-if="goodList.site === 'JP'">￥</i>
                  <i v-if="goodList.site === 'UK'">￡</i>
                  <i v-if="goodList.site === 'CA' || goodList.site === 'MX' || goodList.site === 'DE' ||goodList.site === 'FR' || goodList.site === 'IT' || goodList.site === 'ES'|| goodList.site === 'NL' ">€</i>
                  {{goodList.deal_price}}
                </b>
                <!-- <span>${{goodList.sale_price}}</span> -->
                <span>
                  <span v-if="goodList.site === 'US'">$</span>
                  <span v-if="goodList.site === 'JP'">￥</span>
                  <span v-if="goodList.site === 'UK'">￡</span>
                  <span v-if="goodList.site === 'CA' || goodList.site === 'MX' || goodList.site === 'DE' ||goodList.site === 'FR' || goodList.site === 'IT' || goodList.site === 'ES'|| goodList.site === 'NL' ">€</span>
                  {{goodList.sale_price}}
                </span>
              </div>
              <div class="goods-info">
                <!-- 返现金额 -->
                <div>
                  <!-- <strong>${{cashNum}}</strong> -->
                  <strong>
                    <i v-if="goodList.site === 'US'">$</i>
                    <i v-if="goodList.site === 'JP'">￥</i>
                    <i v-if="goodList.site === 'UK'">￡</i>
                    <i v-if="goodList.site === 'CA' || goodList.site === 'MX' || goodList.site === 'DE' ||goodList.site === 'FR' || goodList.site === 'IT' || goodList.site === 'ES'|| goodList.site === 'NL' ">€</i>
                    {{cashNum}}
                  </strong>
                  <br />Cashback
                </div>
                <!-- 发货方 -->
                <div>
                  <strong>Fullfilled by</strong>
                  <br />Amazon
                </div>
              </div>
              <!-- 获取优惠卷 -->
              <div v-if="$store.state.tokenBool">
                <div v-if="conpouBool">
                  <el-button class="info"
                             type="info"
                             disabled>Get Conpou</el-button>
                  <div class="coupouText"
                       v-if="timeBool">You've requested this product previously. CODE: {{goodList.coupon_code}}</div>
                </div>
                <el-button v-if="!conpouBool"
                           class="coupou"
                           @click="getCom()" :loading="loadingBool">Get Conpou</el-button>
              </div>
              <div v-if="!$store.state.tokenBool">
                <el-button class="coupou"
                           @click="goLogin()">Login</el-button>
              </div>
              <!-- 分享 -->
              <div class="shareBtn">
                <ul class="center">
                  <li class="facebook">
                    <a href="https://www.facebook.com/" target="_blank">
                      <img src="../../assets/img/facebook.svg"
                           alt />
                    </a>
                  </li>
                  <li class="twitter">
                    <a href="http://www.tuiteblog.com/" target="_blank">
                      <img src="../../assets/img/twitter.svg"
                           alt />
                    </a>
                  </li>
                  <li class="pinterest">
                    <a href="https://www.pinterest.com/search/pins/?q=gofundraise" target="_blank">
                      <img src="../../assets/img/pinterest.svg"
                           alt />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="goodRight">
              <!-- 操作流程 -->
              <div class="dealsBox">
                <div class="set-hd">
                  For "DEALS" products, follow the steps as below
                </div>
                <div class="set-bd">
                  <div class="set-bd-item">
                    <img src="../../assets/img/process_5.png"
                         alt />
                    <span>
                      Grab the deal
                      <br />Click on "Get Coupon"
                    </span>
                  </div>
                  <div class="set-bd-item">
                    <img src="../../assets/img/process_6.png"
                         alt />
                    <span>
                      Buy it
                      <br />Go to Amazon
                    </span>
                  </div>
                  <div class="set-bd-item">
                    <img src="../../assets/img/process_7.png"
                         alt />
                    <span>
                      Click finish
                      <br />Click “I'm ordered”
                    </span>
                  </div>
                  <div class="set-bd-item">
                    <img src="../../assets/img/process_8.png"
                         alt />
                    <span>
                      Share Link
                      <br />
                      <span class="spanLink">https://crediscounts.com/</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 这里列举五大卖点 -->
      <div class="fivehot"
           style="padding: 0 30px">{{goodList.description}}</div>
    </div>
    <!-- 下面部分 -->
    <div class="detailsBottom">
      <!-- 推荐产品 -->
      <div class="likeModule">
        <!-- 推荐 -->
        <div class="likeBox">
          <img src="../../assets/img/like.png"
               alt=""
               class="like-icon" />
          <div class="likeTitle">You May Also Like :</div>
        </div>
        <!-- 产品展示 -->
        <div class="imgshows">
          <li class="showbox"
              v-for="(item, index) in likeList"
              :key="index"
              style="background: white">
            <!-- <a href="#"> -->
            <img :src="item.product_image_url"
                 alt />
            <div class="title">{{item.product_title}}</div>
            <div class="fullfilled">Fulfilled by merchant</div>
            <div class="price">
              ${{item.deal_price}}
              <span>${{item.sale_price}}</span>
            </div>
            <div class="twobtn">
              <div class="off">{{item.discount_rate}}% OFF</div>
              <div class="coupon"
                   @click="likeDeatil(item)">Get Coupon</div>
            </div>
            <!-- </a> -->
          </li>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入接口
import {
  getGooddetails, // 获取商品详情
  likeGood, // 收藏商品
  unlikeGood, // cancle收藏
  dealLike, // 获取折扣商品收藏信息
  deatilSearch, // 折扣页面搜索
  getDeal // 折扣发起商品请求
} from '../../api/buyerFront'
import { dealList } from '../../api/buyerAfter'
import { returnFloat } from '../../utils/num'
import { getToken } from '../../utils/token'
export default {
  data () {
    return {
      // 推荐列表
      likeList: [],
      size: '8',
      // 商品详情列表
      goodList: [],
      // 返还金额
      cashNum: '',
      // 结束时间
      timer: '',
      // 收藏
      likeBool: true,
      likeNum: 0,
      // 优惠劵按钮
      conpouBool: false,
      timeBool: true,
      loadingBool: true
    }
  },
  created () {
    if (getToken()) {
      // 获取申请的折扣商品
      dealList({
        size: '', // 数量 （可为空）
        page: '' //  当前页 （可为空）
      }).then(result => {
        if (result.data.code === 200) {
          this.loadingBool = false
          result.data.data.content.map(v => {
            // 判断是否为申请过的折扣商品
            if (this.goodList.product_id === v.product_id) {
              // 是的话禁止在申请
              this.conpouBool = true
              // this.$store.commit('getDealbool', true)
            }
          })
        }
      })
      // 获取折扣收藏商品
      dealLike({
        page: '', // 数量 （可为空）
        size: '' // 当前页 （可为空）
      }).then(result => {
        if (result.data.code === 200) {
          result.data.data.rows.map((v, index) => {
            // 判断商品是否已收藏 从而让收藏图标高亮
            if (v.product_id === this.$route.query.goodId) {
              this.likeNum = 1
              this.likeBool = false
            }
          })
        }
      }).catch(err => {
        return err
      })
    }
    // 获取商品详情
    getGooddetails({
      productId: this.$route.query.goodUrl
    }).then(result => {
      if (result.data.code === 200) {
        this.goodList = result.data.data // 获取商品详情数据
        this.goodList.product_image_url = this.goodList.product_image_url.split('"')[1]
        this.goodList.deal_price = returnFloat(this.goodList.deal_price)
        this.goodList.sale_price = returnFloat(this.goodList.sale_price)
        this.cashNum = returnFloat(parseInt(result.data.data.sale_price - result.data.data.deal_price)) // 计算返还金额
        const timeNum = result.data.data.discount_end_date - result.data.data.discount_start_date // 计算时间
        this.timer = Math.floor((timeNum / 60 / 60) % 24) // 格式化时间
        // // ('(new Date()).getTime() ==>', (new Date()).getTime())
        const goodTime = (new Date()).getTime() // 获取当前时间戳
        // 判断活动时间是否截止
        if (goodTime > this.goodList.discount_end_date) {
          this.timeBool = false
          this.conpouBool = true
          // this.$store.commit('getDealbool', true)
        }
      }
    }).catch(err => {
      return err
    })
    // 获取推荐商品
    deatilSearch({
      categoryId: this.goodList.category, // 类型id
      discountHigherLimit: '', // 折扣率上限
      discountLowerLimit: '', // 折扣率下限
      page: '', // 当前页
      priceHigherLimit: '', // 搜索价格上限
      priceLowerLimit: '', // 搜索价格下限
      site: '', // 国家
      size: this.size, // 页容量
      sorting: '' // 排序依据
    }).then(result => {
      if (result.data.code === 200) {
        result.data.data.rows.map(v => {
          v.product_image_url = v.product_image_url.split('"')[1]
          v.deal_price = returnFloat(v.deal_price)
          v.sale_price = returnFloat(v.sale_price)
        })
        this.likeList = result.data.data.rows // 获取推荐商品列表数据
      }
    }).catch(err => {
      return err
    })
  },
  methods: {
    // 添加喜欢
    likeDeal () {
      const a = this.$route.query.goodId // 获取商品id
      // ('a==>', a)
      // 判断是否有收藏商品
      if (getToken()) {
        if (this.likeBool === true) {
          // 收藏
          likeGood(a).then(result => {
            // // ('result =>', result)
            if (result.data.code === 200) {
              this.likeNum = 1
              this.likeBool = false
              this.$message.success('Collected')
            }
          }).catch(err => {
            return err
          })
        } else {
          // cancle收藏
          unlikeGood(a).then(result => {
            if (result.data.code === 200) {
              this.likeNum = 0
              this.likeBool = true
              this.$message('Cancelled')
            }
            // // ('result =>', result)
          }).catch(err => {
            return err
          })
        }
      } else {
        this.$message('Please log in first')
        setTimeout(() => {
          this.$router.push({ path: '/F_login' })
        }, 500)
      }
    },
    // 跳转优惠劵
    getCom () {
      // (this.goodList.product_id)
      if (getToken()) {
        this.loadingBool = true
        getDeal({ productId: this.goodList.product_id }).then(result => {
          if (result.data.code === 200) {
            // this.$store.commit('getDealbool', true)
            this.loadingBool = false
            this.$router.push({ path: '/F_getConpou', query: { goodId: this.goodList.product_id } })
          }
        }).catch(err => {
          return err
        })
      } else {
        this.$router.push('/F_login')
      }
    },
    // 推荐商品跳转详情
    likeDeatil (item) {
      this.$router.push({ path: '/F_dealDetails', query: { goodUrl: item.url, goodId: item.product_id } })
      this.$router.go(0)
    },
    // 跳转登录
    goLogin () {
      this.$router.push({ path: '/F_login' })
    }
  }
}
</script>

 <style lang='less' scoped>
.dealDetails {
  background-color: #d8d8d8;
  padding-top: 25px;
  text-align: left;
  .detailsTop {
    background-color: #ffffff;
    // max-width: 1500px;
    // height: 865px;
    padding: 30px 15px;
    .goodTop {
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      .detailsleft {
        position: relative;
        padding-right: 15px;
        img {
          display: block;
          width: 450px;
          height: 512px;
        }
        .AmazonBtn {
          position: absolute;
          bottom: 0;
          font-size: 14px;
          background: #f93;
          padding: 5px 15px;
          color: white;
        }
      }
      .detailsRight {
        width: 70%;
        padding-left: 15px;
        .goods-expires {
          line-height: 21px;
          font-size: 14px;
          color: #5b5b5b;
          .el-icon-alarm-clock {
            margin-right: 5px;
          }
          span {
            color: #ff0100;
          }
        }
        h1 {
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          margin-bottom: 10px;
        }
        .country-text {
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          // position: relative;
          .countryBox {
            display: flex;
            align-items: center;
            img {
              margin-right: 10px;
            }
          }
          .chooesBox {
            display: flex;
            justify-content: center;
            align-items: center;
            .likeBox {
              display: flex;
              justify-content: center;
              align-items: center;
              &:nth-child(2) {
                cursor: pointer;
              }
              li {
                width: 32px;
                height: 32px;
                margin-right: 15px;
                text-align: right;
                line-height: 32px;
                // display: flex;
                // justify-content: flex-end;
                // align-items: center;
                background: url("../../assets/img/icon-interaction-tips.png")
                  no-repeat;
              }
              .solid {
                background-position: -6px -33px;
              }
              .hollow {
                background-position: -6px -66px;
              }
              .good {
                background-position: -6px -102px;
              }
              .bad {
                background-position: -6px -134px;
              }
            }
          }
        }
        .goodBox {
          display: flex;
          justify-content: space-between;
          .goodLeft {
            width: 50%;
            .goods-price {
              margin: 10px 0;
              font-size: 48px;
              color: #ff9933;
              font-weight: bolder;
              line-height: 1.3;
              span {
                font-size: 16px;
                text-decoration: line-through;
                color: #b1b1b1;
                font-weight: 400;
              }
            }
            .goods-info {
              width: 70%;
              border-top: 1px solid #efefef;
              border-bottom: 1px solid #efefef;
              display: flex;
              justify-content: flex-start;
              padding: 5px;
              box-sizing: border-box;
              div {
                text-align: center;
                width: 33%;
                font-size: 16px;
                line-height: 32px;
                color: #666;
              }
            }
            .coupou {
              margin-top: 10px;
              width: 70%;
              background: #ff9933;
              color: white;
              font-size: 32px;
              line-height: 60px;
              padding: 0;
            }
            .info {
              margin-top: 10px;
              width: 70%;
              font-size: 32px;
              line-height: 60px;
              padding: 0;
            }
            .coupouText {
              margin: 5px 0;
              width: 68%;
              padding: 5px;
              border: 1px dashed #f93;
              background-color: rgb(255, 220, 184);
              color: black;
              font-size: 14px;
              font-weight: 600;
            }
            .shareBtn {
              width: 70%;
              margin-top: 10px;
              .center {
                display: flex;
                justify-content: center;
                // background-color: black;
                li {
                  margin: 0 5px;
                  border-radius: 3px;
                  transition: all 0.5s;
                  a {
                    width: 32px;
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  &:hover {
                    transform: translateY(-5px);
                    opacity: 0.8;
                  }
                }
                .facebook {
                  background-color: #4267b2;
                }
                .twitter {
                  background-color: #55acee;
                }
                .pinterest {
                  background-color: #cb2027;
                }
                .sharelink {
                  background-color: #95d03a;
                }
              }
            }
          }
          .goodRight {
            margin-top: 10px;
            width: 400px;
            border: 1px solid #cdd4b6;
            .dealsBox {
              .set-hd {
                text-align: center;
                font-size: 17px;
                color: white;
                background-color: #f3b24c;
                line-height: 48px;
              }
              .set-bd {
                background-color: #f5ffd7;
                // box-sizing: border-box;
                .set-bd-item {
                  padding: 3px 0 3px 16px;
                  display: flex;
                  justify-content: flex-start;
                  border-top: 1px solid #cdd4b6;
                  img {
                    display: block;
                    height: 60px;
                    width: auto;
                    margin-right: 16px;
                  }
                  span {
                    font-size: 20px;
                    line-height: 1.5;
                    span {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .fivehot {
      margin-top: 20px;
      line-height: 32px;
      font-size: 16px;
      text-align: left;
    }
  }
  .detailsBottom {
    padding: 36px 0;
    background-color: #f1f1f1;
    .comment {
      padding: 0 150px;
      display: flex;
      justify-content: flex-start;
      .comment-user {
        width: 10%;
        font-size: 26px;
      }
      .comment-text {
        width: 80%;
        text-align: left;
        h3 {
          margin-bottom: 15px;
          font-size: 20px;
          line-height: 30px;
        }
        .el-button {
          margin-top: 15px;
          margin-bottom: 15px;
          font-size: 16px;
          font-weight: bolder;
          color: white;
          background-color: #ff9933;
        }
      }
    }
    .likeModule {
      margin: 0 auto;
      max-width: 1500px;
      width: 100%;
      .likeBox {
        display: flex;
        align-items: center;
      }
      .like-icon {
        margin-right: 7px;
      }
      .likeTitle {
        display: inline-block;
        font-size: 28px;
        font-weight: bold;
        margin-left: 10px;
        line-height: 1.5;
      }

      .imgshows {
        margin-top: 30px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        &::after {
          content: "";
          flex: auto;
        }
        li {
          margin-right: 25px;
          width: 15%;
          height: 388px;
          padding: 15px;
          box-sizing: border-box;
          border: 1px solid #ccc;
          border-radius: 1px;
          margin-bottom: 70px;
          // a {
          img {
            width: 100%;
            height: 55%;
            margin: 0 auto;
          }
          .title {
            font-size: 18px;
            line-height: inherit;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 10px;
          }
          .fullfilled {
            text-align: left;
            color: #999;
            margin: 10px auto 20px;
          }
          .price {
            font-size: 24px;
            line-height: inherit;
            color: #ff9933;
            font-weight: bolder;
            margin: 10px auto 10px;
            span {
              font-size: 16px;
              font-weight: normal;
              text-decoration: line-through;
            }
          }
          .twobtn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .off {
              padding: 3px 6px;
              background-color: #e9ecef;
              font-size: 12px;
              border-radius: 2px;
              line-height: 24px;
            }
            .coupon {
              font-size: 12px;
              color: #fff;
              background: #ff9933;
              border-radius: 3px;
              padding: 3px 6px;
              font-weight: bolder;
              line-height: 24px;
              cursor: pointer;
            }
          }
          // }
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-button:active {
  border-color: transparent !important;
}
</style>
